"use strict";

document.addEventListener('DOMContentLoaded', function (event) {
  function subFP() {
    PubSub.subscribe('gotoScreen', function (msg, data) {
      //start goToNextScreen button
      var nextScreenBtn = document.querySelector('.js-pagination__next');

      if (data.to == document.querySelectorAll('[data-screen]').length) {
        nextScreenBtn.classList.add('to-first');
        nextScreenBtn.setAttribute('data-gotoscreen', 1);
      } else {
        nextScreenBtn.classList.remove('to-first');
        nextScreenBtn.setAttribute('data-gotoscreen', parseInt(data.to) + 1);
      } //end goToNextScreen button
      //start pagination


      var pagination = document.querySelectorAll('.pagination ul a'),
          currentPagination = document.querySelector('[data-gotoscreen="' + data.to + '"]');
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = pagination[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var elem = _step.value;
          elem.classList.remove('is-active');
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator["return"] != null) {
            _iterator["return"]();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      currentPagination.classList.add('is-active'); //end pagination
      //screen

      var currentScreen = document.querySelector('[data-screen="' + data.from + '"]'),
          newScreen = document.querySelector('[data-screen="' + data.to + '"]'),
          elements = currentScreen.querySelectorAll('[data-stagger="true"]'),
          newElements = newScreen.querySelectorAll('[data-stagger="true"]');
      currentScreen.classList.remove('is-active');
      newScreen.classList.add('is-active'); //end screen
      //start TimeLine

      var windowHeight = window.innerHeight;
      var tl = new TimelineMax({
        onComplete: function onComplete() {
          new TimelineMax();
        }
      });

      if (data.from > data.to) {
        windowHeight = -windowHeight;
      }

      var my = tl.set(newScreen, {
        zIndex: 100
      }).set(currentScreen, {
        zIndex: 100
      }).fromTo(currentScreen, 0.5, {
        y: 0
      }, {
        y: -windowHeight
      }, 0).fromTo(newScreen, 0.5, {
        y: windowHeight
      }, {
        y: 0
      }, 0).set(currentScreen, {
        zIndex: 'auto'
      });

      if (data.to === 2) {
        var svgNumbers = document.querySelectorAll('.js-manufacture__item-number:not(.is-active)');
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = svgNumbers[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var elem = _step2.value;
            var start = elem.getAttribute('data-count-start'),
                end = elem.getAttribute('data-count-end'),
                duration = 1.5,
                counter = new CountUp(elem, 0, end, start, duration, {
              useEasing: false,
              useGrouping: false,
              separator: '',
              decimal: ''
            });
            counter.start();
            elem.classList.add('is-active');
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2["return"] != null) {
              _iterator2["return"]();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }
      } //end TimeLine

    });
  }

  function unsubFP() {
    PubSub.unsubscribe('gotoScreen');
  }

  function OPSModule() {
    this.scrollEvents();
    this.clickEvents();
    this.activeScreen = 1;
    this.canGo = 1;
    this.max = document.querySelectorAll('[data-screen]').length;
    this.delay = 500;
  }

  OPSModule.prototype.scrollEvents = function () {
    var self = this;
    window.addEventListener('wheel', function (event) {
      if (window.innerWidth > 1199 && !document.body.classList.contains('modal-open')) {
        if (!self.canGo) {
          return;
        }

        var direction = event.deltaY > 0 ? 1 : -1;
        var newScreen = parseInt(self.activeScreen) + direction;

        if (newScreen > self.max || newScreen < 1) {
          return;
        }

        self.canGo = false;
        PubSub.publish('gotoScreen', {
          from: self.activeScreen,
          to: newScreen
        });
        self.activeScreen = newScreen;
        setTimeout(function () {
          self.canGo = true;
        }, self.delay);
      }
    });
  };

  OPSModule.prototype.clickEvents = function () {
    var self = this,
        pagination = document.querySelectorAll('.pagination a');
    var _iteratorNormalCompletion3 = true;
    var _didIteratorError3 = false;
    var _iteratorError3 = undefined;

    try {
      for (var _iterator3 = pagination[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
        var elem = _step3.value;
        elem.addEventListener('click', function (event) {
          event.preventDefault();

          if (window.innerWidth > 1199) {
            if (!self.canGo) return;
            self.canGo = false;
            var newScreen = this.getAttribute('data-gotoscreen');

            if (newScreen !== self.activeScreen) {
              PubSub.publish('gotoScreen', {
                from: self.activeScreen,
                to: newScreen
              });
              self.activeScreen = newScreen;
              setTimeout(function () {
                self.canGo = true;
              }, self.delay);
            }
          }
        });
      }
    } catch (err) {
      _didIteratorError3 = true;
      _iteratorError3 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion3 && _iterator3["return"] != null) {
          _iterator3["return"]();
        }
      } finally {
        if (_didIteratorError3) {
          throw _iteratorError3;
        }
      }
    }
  };

  subFP();
  var opsModule = new OPSModule();
  var oneResFP = window.innerWidth <= 1199;
  window.addEventListener('resize', function () {
    if (window.innerWidth > 1199 && oneResFP) {
      oneResFP = false;
    }

    if (window.innerWidth <= 1199 && !oneResFP) {
      oneResFP = true;
      var _iteratorNormalCompletion4 = true;
      var _didIteratorError4 = false;
      var _iteratorError4 = undefined;

      try {
        for (var _iterator4 = document.querySelectorAll('[data-screen], [data-stagger]')[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
          var elem = _step4.value;
          elem.removeAttribute('style');
        }
      } catch (err) {
        _didIteratorError4 = true;
        _iteratorError4 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion4 && _iterator4["return"] != null) {
            _iterator4["return"]();
          }
        } finally {
          if (_didIteratorError4) {
            throw _iteratorError4;
          }
        }
      }
    }
  });
});